.hero {
  background-image: url("../../../../public/images/young-woman-with-sunglasses-with-laptop-internet-shopping-concept.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}


.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.131); /* Black overlay with 50% transparency */
}



.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 50vh;
  }
  .hero {
    background-position: right;
    padding-top:50%;
    height: 94vh;
  }
  .hero .row {
    width: 100%;
  }
  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.278); /* Black overlay with 50% transparency */
  }
  #heading h3{
    position: relative;
    /* font-size: 30px */
  }
  #heading h1{
    position: relative;
    font-size: 30px
  }
  
  
}
